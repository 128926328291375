import React, { useState } from 'react';
import './index.css';
import EmailModal from '../emailModal/emailModal';

export default function NavBar() {
    const [ active, setActive ] = useState(false);

    return(
        <>
            <div className="navBody">
                <div className="logo"></div>
                <p className="bigName">SALVE TEA</p>
                <div className="buttonContainer">
                    <a className="contactUs" onClick={() => setActive(!active) } style={(active ? { backgroundColor: 'white', color: 'black' } : {})}>
                        <p style={{display:'flex'}}>CONTACT US</p>
                    </a>
                </div> 
            </div>
            <EmailModal display={active}/>
            <div onClick={() => setActive(false)} style={{ display: (active ? 'block' : 'none') }} className="clickTrap"></div>
        </>
    );
}